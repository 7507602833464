<template>
	<div class="payPass">
		<div class="payPassBox">
			<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			    <el-tab-pane label="初始化登录密码" name="1" v-if="urPassword==''">
					<el-form
						:model="ruleFormCode"
						:rules="rulesCode"
						ref="ruleFormCode"
						label-position="top"
						class="ruleForm"
						v-if="urPassword == ''"
					>
						<el-form-item label="手机号" prop="urMobile">
						  <el-input v-model="ruleFormCode.urMobile" disabled placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
						  <div style="display: flex;">
						    <div style="flex:1;width:344px;">
						      <el-input v-model="ruleFormCode.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
						    </div>
						    <div style="margin-left:4px;">
						      <el-button type="primary" :disabled="disableSend" @click="sendBtn">{{sendText}}</el-button>
						    </div>
						  </div>
						</el-form-item>
						<el-form-item label="登录密码" prop="urPassword">
						  <el-input v-model="ruleFormCode.urPassword" type="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="comPass">
						  <el-input v-model="ruleFormCode.comPass" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item>
						  <el-button type="primary" @click="submitForm('ruleFormCode')" round style="width:100%" :loading="loading">设置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			    <el-tab-pane label="修改密码" name="2" v-if="urPassword!=''">
					<el-form
						:model="ruleFormPass"
						:rules="rulesPass"
						ref="ruleFormPass"
						label-position="top"
						class="ruleFormPass"
						v-if="urPassword != ''"
					>
						<el-form-item label="手机号" prop="urMobile">
						  <el-input v-model="ruleFormPass.urMobile" disabled placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="原密码" prop="oldPass">
						  <el-input v-model="ruleFormPass.oldPass" type="password" placeholder="请输入原密码"></el-input>
						</el-form-item>
						<el-form-item label="登录密码" prop="urPassword">
						  <el-input v-model="ruleFormPass.urPassword" type="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="comPass">
						  <el-input v-model="ruleFormPass.comPass" type="password" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item>
						  <el-button type="primary" @click="submitFormPass('ruleFormPass')" round style="width:100%" :loading="loading">设置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			    <el-tab-pane label="找回密码" name="3" v-if="urPassword!=''">
					<el-form
						:model="ruleForm"
						:rules="rules"
						ref="ruleForm"
						label-position="top"
						class="ruleForm"
					>
						<el-form-item label="手机号" prop="ceMobile">
						  <el-input v-model="ruleForm.ceMobile" disabled placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
						  <div style="display: flex;">
						    <div style="flex:1;width:344px;">
						      <el-input v-model="ruleForm.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
						    </div>
						    <div style="margin-left:4px;">
						      <el-button type="primary" :disabled="disableSend" @click="slideBtn">{{sendText}}</el-button>
						    </div>
						  </div>
						</el-form-item>
						<el-form-item label="密码" prop="urPassword">
						  <el-input type="password" v-model="ruleForm.urPassword" placeholder="请输入密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="comPassword">
						  <el-input type="password" v-model="ruleForm.comPassword" placeholder="请输入确认密码"></el-input>
						</el-form-item>
						<el-form-item>
						  <el-button type="primary" @click="submitFormFind('ruleForm')" round style="width:100%" :loading="loading">确认</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
	<el-dialog
		width="500px"
		top="30vh"
		title="请按住滑块，拖动到最右边"
		v-model="dialogVisible"
	    :before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:destroy-on-close="true"
		custom-class="dialog"
	>
		<div id="nc"></div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			const validatePass = (rule,value, callback) => {
				if(!(/((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,16}/i.test(value))){  
					callback(new Error('密码格式为字母、数字和字符串组合'))
				} else {
					callback()
				}
			}
			const validateComPass = (rule,value, callback) => {
				let urPassword = this.ruleFormCode.urPassword;
				if(urPassword != value){
					callback(new Error('两次密码输入不一致'))
				}else{
					callback()
				}
			}
			const validatePassCode = (rule,value, callback) => {
				if(!(/((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,16}/i.test(value))){ 
					callback(new Error('密码格式为字母、数字和字符串组合'))
				} else {
					callback()
				}
			}
			const validateComPassCode = (rule,value, callback) => {
				let urPassword = this.ruleFormPass.urPassword;
				if(urPassword != value){
					callback(new Error('两次密码输入不一致'))
				}else{
					callback()
				}
			}
			const validateFindPass = (rule,value, callback) => {
				let urPassword = this.ruleForm.urPassword;
				if(urPassword != value){
					callback(new Error('两次密码输入不一致'))
				}else{
					callback()
				}
			}
			return{
				disableSend:false,
				sendText:'发送验证码',
				sendTime: 120,
				timer: null,
				dialogVisible:false,
				loading:false,
				showUpdatePass:false,
				showInitPass:false,
				activeName:'1',
				urPassword:'',
				ruleForm:{
					ceMobile: '',
					urPassword: '',
					comPassword:'',
					code:'',
					type:1
				},
				rules: {
					urPassword: [
						{ required: true, validator: validatePass, trigger: 'change' }
					],
					comPassword: [
						{ required: true, validator: validateFindPass, trigger: 'change' }
					],
					code: [
						{ required: true, message: '验证码不能为空', trigger: 'change' }
					]
				},
				ruleFormPass:{
					urMobile: '',
					code:'',
					urPassword:'',
					oldPass:'',
					urId:window.localStorage.getItem("urId")
					
				},
				ruleFormCode:{
					urMobile: '',
					code:'',
					urPassword:'',
					comPass:'',
					type:0,
					urId: window.localStorage.getItem("urId")
				},
				rulesCode:{
					code: [
						{ required: true, message: '手机验证码不能为空', trigger: 'change' }
					],
					urPassword: [
						{ required: true, validator: validatePass, trigger: 'change' }
					],
					comPass: [
						{ required: true, validator: validateComPass, trigger: 'change' }
					],
				},
				rulesPass:{
					oldPass: [
						{ required: true, message: '原不能为空', trigger: 'change' }
					],
					urPassword: [
						{ required: true, validator: validatePassCode, trigger: 'change' }
					],
					comPass: [
						{ required: true, validator: validateComPassCode, trigger: 'change' }
					],
				},
			}
		},
		created() {
			this.getObject();
		},
		methods: {
			submitFormFind(formName){
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
						if(!reg.test(that.ruleForm.ceMobile)){ 
							that.$message.error('请输入正确的手机号码');
							return false;
						}
						if(that.ruleForm.urPassword != that.ruleForm.comPassword){
							that.$message.error('两次密码输入不一致！');
							return false;
						}
						that.ruleForm.urMobile = that.ruleForm.ceMobile;
						that.loading = true;
						this.$axios({
							method:'post',
							url:'user/ur/initPass',
							data:this.$qs.stringify(that.ruleForm)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg==2){
								that.$message.success("保存成功！");
								that.ruleForm = {
									ceMobile: '',
									urPassword: '',
									comPassword:'',
									code:'',
									type:1
								};
							}else{
								that.$message.error(msg);
							}
							that.loading = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			submitFormPass(ruleFormPass){
				let that = this;
				that.$refs[ruleFormPass].validate((valid) => {
					if (valid) {
						that.loading = true;
						this.$axios({
							method:'post',
							headers: {
								token: window.localStorage.getItem("token")
							},
							url:'user/ur/setPass',
							data:this.$qs.stringify(that.ruleFormPass)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg == 2){
								that.$message.success("保存成功");
								that.ruleFormPass={
									urMobile: '',
									code:'',
									urPassword:'',
									oldPass:'',
									urId:window.localStorage.getItem("urId")
									
								};
								// that.getObject();
							}else{
								that.$message.error(msg);
							}
							that.loading = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			submitForm(formName){//初始密码
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let comPass = that.rulesCode.comPass;
						if(comPass == ""){
							that.$message.error("请输入确认密码!");
							return false;
						}
						that.loading = true;
						this.$axios({
							method:'post',
							headers: {
								token: window.localStorage.getItem("token")
							},
							url:'user/ur/initPass',
							data:this.$qs.stringify(that.ruleFormCode)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg == 2){
								that.getObject();
								that.activeName = "2";
								that.$message.success("保存成功!");
								that.ruleFormCode={
									urMobile: '',
									code:'',
									urPassword:'',
									comPass:'',
									urId: window.localStorage.getItem("urId")
								};
							}else{
								that.$message.error(msg);
							}
							that.loading = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			slideBtn(){
				let that = this;
				let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
				if(!reg.test(that.ruleForm.ceMobile)){ 
					that.$message.error('请输入正确的手机号码');
					return false;
				} 
				that.dialogVisible = true;
				that.$nextTick(()=>{
					AWSC.use("nc", function (state, module) {
						window.nc = module.init({
							appkey: "FFFF0N0000000000AA0F",
							scene: "nc_message",
							renderTo: "nc",
							width:450,
							success: function (data) {
								that.sendSMS(data.sessionId,data.sig,data.token);
								that.dialogVisible = false;
							},
							fail: function (failCode) {
								 window.console && console.log(failCode)
								 window.nc.reset();
							},
							error: function (errorCode) {
								window.nc.reset();
								 window.console && console.log(errorCode)
							}
						});
					})
				})
			},
			sendSMS(sessionId,sig,token){
				let that =this;
				this.$axios({
					method:'post',
					url:'sys/ce/sendFindPass',
					data:this.$qs.stringify({
						sessionId: sessionId,
						sig: sig,
						token: token,
						ceMobile: that.ruleForm.ceMobile
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg==2){
						that.$message.success("验证码发送成功，120秒内有效，请注意查收！");
						that.startCounter();
					}else{
						window.nc.reset();
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			sendBtn(){
				let that =this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'sys/ce/sendInitPass',
					data:this.$qs.stringify({
						urMobile: that.ruleFormCode.urMobile,
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg==2){
						that.$message.success("验证码发送成功，120秒内有效，请注意查收！");
						that.startCounter();
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			startCounter(){
				let that = this;
				that.disableSend = true;
				that.timer = setInterval(()=> {
					that.sendTime --;
					that.sendText = this.sendTime+"秒后可重新发送";
					if(that.sendTime <= 0 ) {
						that.disableSend = false;
						clearInterval(that.timer);
						that.sendTime = 120;
						that.sendText = "发送验证码";
					}
				},1000);
			},
			getObject(){
				let that =this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'user/ur/getObject',
					data:this.$qs.stringify({
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					this.ruleFormCode.urMobile = res.data.data.urMobile;
					this.ruleFormPass.urMobile = res.data.data.urMobile;
					this.ruleForm.ceMobile = res.data.data.urMobile;
					this.urPassword = res.data.data.urPassword;
					if(this.urPassword != ''){
						this.activeName = "2";
					}else{
						this.activeName = "1";
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
		},
	}
</script>

<style scoped>
	.payPassBox{
		width:550px;
		padding:15px;
	}
	.payPassBox .el-input{
		height:40px;
	}
	.payPassBox .el-button{
		height:40px;
	}
</style>
